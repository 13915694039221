/*! Tocca.js v0.1.0 || Gianluca Guarini */
!function(a,b){"use strict";if("function"!=typeof a.createEvent)return!1;var c,d,e,f,g,h="undefined"!=typeof jQuery,i=!!navigator.pointerEnabled||navigator.msPointerEnabled,j=!!("ontouchstart"in window)&&navigator.userAgent.indexOf("PhantomJS")<0||i,k=function(a){var b=a.toLowerCase(),c="MS"+a;return navigator.msPointerEnabled?c:b},l={touchstart:k("PointerDown")+" touchstart",touchend:k("PointerUp")+" touchend",touchmove:k("PointerMove")+" touchmove"},m=function(a,b,c){for(var d=b.split(" "),e=d.length;e--;)a.addEventListener(d[e],c,!1)},n=function(a){return a.targetTouches?a.targetTouches[0]:a},o=function(b,e,f,g){var i=a.createEvent("Event");if(g=g||{},g.x=c,g.y=d,g.distance=g.distance,h)jQuery(b).trigger(e,g);else{i.originalEvent=f;for(var j in g)i[j]=g[j];i.initEvent(e,!0,!0),b.dispatchEvent(i)}},p=function(a){var b=n(a);e=c=b.pageX,f=d=b.pageY,s=!0,x++,clearTimeout(g),g=setTimeout(function(){e>=c-v&&c+v>=e&&f>=d-v&&d+v>=f&&!s&&o(a.target,2===x?"dbltap":"tap",a),x=0},u)},q=function(a){var b=[],g=f-d,h=e-c;if(s=!1,-t>=h&&b.push("swiperight"),h>=t&&b.push("swipeleft"),-t>=g&&b.push("swipedown"),g>=t&&b.push("swipeup"),b.length)for(var i=0;i<b.length;i++){var j=b[i];o(a.target,j,a,{distance:{x:Math.abs(h),y:Math.abs(g)}})}},r=function(a){var b=n(a);c=b.pageX,d=b.pageY},s=!1,t=b.SWIPE_TRESHOLD||80,u=b.TAP_TRESHOLD||200,v=b.TAP_PRECISION/2||30,w=b.JUST_ON_TOUCH_DEVICES||j,x=0;m(a,l.touchstart+(w?"":" mousedown"),p),m(a,l.touchend+(w?"":" mouseup"),q),m(a,l.touchmove+(w?"":" mousemove"),r)}(document,window);

$(document).ready(function() {

    if ($('.slide-wrapper').length) {



        function startSlide() {
            slideInterval = setInterval(function() {
                    switchImage('next');
            }, 6000);
        }

        function switchImage(order) {
            var activeSlide = $('.slide-wrapper .slide:visible:first');
            var nextSlide;

            if ( order == 'prev' ) {
                nextSlide = activeSlide.prev('.slide');
                if (!nextSlide.length) {
                    nextSlide = $('.slide-wrapper .slide:last');
                }
            } else if ( order == 'next' )  {
                nextSlide = activeSlide.next('.slide');
                if (!nextSlide.length) {
                    nextSlide = $('.slide-wrapper .slide:first');
                }
            } else {
                clearInterval(slideInterval);
                return false
            }

            nextSlide.fadeIn(800);
            activeSlide.fadeOut(800);

            var index = nextSlide.index();

            $('.slide-nav-buttons li.active').removeClass('active');
            $('.slide-nav-buttons li:eq('+index+')').addClass('active');

	        if($('.slide-wrapper').length) {
		     	$('.slide-wrapper').css('height', $('.slide-wrapper .slide:first').outerHeight());
		    }

        }

        function switchImageIndex(index) {
            var activeSlide = $('.slide-wrapper .slide:visible');
            var nextSlide = $('.slide-wrapper .slide:eq('+index+')');
            nextSlide.fadeIn(800);
            activeSlide.fadeOut(800);
        }

        $('.slide-wrapper > .slide').hide();
        $('.slide-wrapper > .slide:first').show();

        $('.slide-wrapper .prev').live({
            click: function() {
                clearInterval(slideInterval);
                switchImage('prev');
            }
        });
        $('.slide-wrapper .next').live({
            click: function() {
                clearInterval(slideInterval);
                switchImage('next');
            }
        });

        $('.slide-wrapper').on('swipeleft', function() {
	        clearInterval(slideInterval);
            switchImage('next');

        });

        $('.slide-wrapper').on('swiperight', function() {
	        clearInterval(slideInterval);
            switchImage('prev');
        });

        if ( $('.slide-wrapper .slide').length > 1 && !$('body.single').length ) {
            startSlide();
        }

        $('.slide-wrapper').css('height', $('.slide-wrapper .slide:first').outerHeight());
    }

    if ($('[data-colorbox]').length) {
        $('[data-colorbox]').colorbox({rel:'group1', transition:'fade', maxWidth:'80%', maxHeight:'80%'});
    }

    $('img').attr({'title':''});

    $('.menu-icon').click(function() {
	    $('#nav ul').toggleClass('view');
    });

    $('#colorbox').live('swipeleft', function() {
	    $.colorbox.next();
    });

    $('#colorbox').live('swiperight', function() {
	    $.colorbox.prev();
    });

    $(window).resize(function() {
	    if($('.slide-wrapper').length) {
	     	$('.slide-wrapper').css('height', $('.slide-wrapper .slide:first').outerHeight());
	    }
    });
});

window.addEventListener('DOMContentLoaded',function(){
	new ModalVideo("[data-video-id]",{channel:'vimeo'});
});

$("[data-video-id]").on("click", function(e) {
  e.preventDefault();
})
